import DynamicAutocomplete from "./DynamicAutocomplete";
import {VendorsBrandsListAllURL} from "../../urls";

export const VendorBrandAutoComplete = (props)=>{
    const vendor = props.vendor || "";
    const fetch_all = props.fetch_all || "";

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={VendorsBrandsListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
            params_array={[vendor, fetch_all]}
            params={{vendor, fetch_all}}
        />
    )
}
