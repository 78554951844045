const orderCustomerReceiptTemplate = (data = {}) => {
    return `
  <style type="text/css" media="print">
  
        .font-size-11 {
            font-size: 11px !important;
        }

        .font-size-12 {
            font-size: 12px !important;
        }

        .font-size-13 {
            font-size: 13px !important;
        }

        .font-size-14 {
            font-size: 14px !important;
        }

        .font-size-15 {
            font-size: 15px !important;
        }

        .font-size-16 {
            font-size: 16px !important;
        }

        .font-size-17 {
            font-size: 17px !important;
        }

        .font-size-18 {
            font-size: 18px !important;
        }

        .font-size-20 {
            font-size: 20px !important;
        }

        .font-size-22 {
            font-size: 22px !important;
        }
        
  @media print {
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}
  @page {
            size: A4;
            margin: 5px !important;
            padding: 5px !important;
        }    
        
        body, html{
           margin: 5px !important;
            padding: 5px !important;
        }     
         </style>

<div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: space-between">
             
             <div style="width: 100%">
                                    <div style="border-bottom: 1px lightgray solid; padding-bottom: 5px;">
                                        <img src="https://www.galleriasapp.com/img/logo_2.png" style="width:150px">
                                  </div>
                                  
                                                    <h1 class="font-size-20" style="text-align: center; padding: 30px 0 0 0;">
                                                        Order Receipt
                                                    </h1>

                                                    <table style="padding: 30px 0;">
                                                        <tr><td colspan="6"><p class="font-size-16" style="border-bottom: 1px lightgray solid; font-weight: bold;">Order Information</p></td></tr>
                                                   
                                                        <tr>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Order Ref</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.order_ref_no}</td>
                                                            
                                                           <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Customer Name</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.customer_name}</td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Customer Email</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.customer_email}</td>
                                                         
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Customer Contact</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.customer_contact_number}</td>
                                                        </tr>
                                                        
                                                        ${!data.is_gift_order ? `
                                                        <tr>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Delivery Within</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.delivery_within}</td>
                                                            
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Address</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.full_address}</td>
                                                        </tr>
                                                        ` : ""}
                                                        
                                                        
                                                    </table>
                                                    
                                                    
                                                    
                                                    ${data.is_gift_order ? `
                                                    <table style="padding: 30px 0;">
                                                        <tr><td colspan="6"><p class="font-size-16" style="border-bottom: 1px lightgray solid; font-weight: bold;">Recipient Information</p></td></tr>

                                                        <tr>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Recipient Name</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.recipient_name}</td>
                                                              
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Recipient Number</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.recipient_contact_number_view}</td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Delivery Within</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.delivery_within}</td>
                                                            
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="15%">Address</td>
                                                            <td>:</td>
                                                            <td class="font-size-13" style="font-weight:400;text-align:left;padding: 3px 6px;" width="35%">${data.full_address}</td>
                                                        </tr>
                                                    </table>
                                                    ` : ""}




                                                    

                                                    <table style="width: 100%; overflow: auto; padding: 30px 0;">
                                                        <tr>
                                                            <th class="font-size-14" style="font-weight:bold;text-align:left;padding: 3px 6px; border-bottom: 1px lightgray solid;" width="15%">Brand</th>
                                                            <th class="font-size-14" style="font-weight:bold;text-align:left;padding: 3px 6px; border-bottom: 1px lightgray solid;" width="55%">Item</th>
                                                            <th class="font-size-14" style="font-weight:bold;text-align:center;padding: 3px 6px; border-bottom: 1px lightgray solid;" width="10%">Qty</th>
                                                            <th class="font-size-14" style="font-weight:bold;text-align:right;padding: 3px 6px; border-bottom: 1px lightgray solid;" width="20%">Amount</th>
                                                        </tr>

                                                       ${(data.OrderItems || []).map(item => (
        `
                                                            <tr style="border-bottom: solid 1px #f7f7f7;">

                                                            <td class="font-size-13" style="font-weight:400;padding: 5px 6px;vertical-align:top;text-align:left;">
                                                             ${item.brand_name_en}
                                                            </td>
                                                            <td class="font-size-13" style="font-weight:400;padding: 5px 6px;vertical-align:top;text-align:left;">
                                                             ${item.product_name_en}
                                                            </td>
                                                            <td class="font-size-13" style="font-weight:400;padding: 5px 6px;vertical-align:top;text-align:center;">
                                                                ${item.qty}
                                                            </td>
                                                            <td class="font-size-13" style="font-weight:400;padding: 5px 6px;vertical-align:top;text-align:right">
                                                                ${item.total_price}
                                                            </td>

                                                        </tr>
                                                           `
    )).join("")}

                                                    </table>


                                                    <table style="width: 100%!important; margin-bottom: 20px; border-top: 1px solid lightgray;">
                                                        <tr>
                                                            <th width="65%"></th>
                                                            <th width="15%"></th>
                                                            <th width="20%"></th>
                                                        </tr>
                                                        
                                                        <tr style="border-top: solid 2px #ccc;">
                                                            <td></td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">Gift Price :</td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">${data.items_price}</td>
                                                        </tr>
                                                    
                                                    
                                                        <tr style="border-top: solid 2px #ccc;">
                                                            <td></td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">Delivery Fees :</td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">${data.delivery_fees}</td>
                                                        </tr>
                                                        
                                                        ${data.total_discount_amount > 0 ? `

                                                        <tr style="border-top: solid 2px #ccc;">
                                                            <td></td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">Price Overall :</td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">${data.price_overall}</td>
                                                        </tr>
                                                        
                                                        <tr style="border-top: solid 2px #ccc;">
                                                            <td></td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;color: red;" align="right">Total Discount :</td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;color: red;" align="right">${data.total_discount_amount}</td>
                                                        </tr>
                                                        ` : ""}

                                                        <tr style="border-top: solid 2px #ccc;">
                                                            <td></td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">Total :</td>
                                                            <td class="font-size-13" style="font-weight:400;vertical-align:top;border-bottom: 1px solid lightgray;" align="right">${data.net_total}</td>
                                                        </tr>
                                                        
                                                    </table>  

</div>

                                    <div style="border-top: 1px lightgray solid; padding-top: 5px;">
                              <div class="font-size-13" style="text-align: start;">
                              Email: info@galleriasapp.com   -   Contact: +965 6659 2225   -   Website: www.galleriasapp.com
                           </div>
                                    
                                  </div>
                                  
                                  </div>



`
}


export default orderCustomerReceiptTemplate;
