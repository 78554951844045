import {Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {PromoCodesURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {IsActiveView} from "../../global/components/IsActiveView";
import Hide from "../../global/components/Hide";
import {isVendor} from "../../global/auth_functions";
import Cookies from "js-cookie";

export const PromoCodes = props =>{
    const {slug} = props;
    const [reload, setReload] = useState(false);
    const is_vendor = isVendor();

    const [promo_code_perm] = useState({
        delete: checkPermission(permission_data.promo_code.delete),
        edit: checkPermission(permission_data.promo_code.edit),
        add: checkPermission(permission_data.promo_code.add),
    });

    const [columns] = useState([
        {
            title: "Title",
            dataIndex: "title",
            width: "20%"
        },
        {
            title: "Type",
            dataIndex: "type",
            width: "10%"
        },

        {
            title: "Apply On",
            dataIndex: "apply_on",
            width: "10%",
        },
        {
            title: "Code",
            dataIndex: "code",
            width: "5%",
            align:"center",
        },
        {
            title: "Start",
            dataIndex: "start_date_view",
            width: "15%",
            align:"center",
        },
        {
            title: "End",
            dataIndex: "end_date_view",
            width: "15%",
            align:"center",
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!promo_code_perm.edit}/>
        },

    ]);

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PromoCodesURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Promo Code", `${row_data.title} promo code has been deleted`)
        }else{
            openNotification("error", "Delete Promo Code", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = (row_data, current_page) =>{
        Cookies.set("promo_codes_open_in_page", current_page, {expires: 1/48/6});

        props.history.push(`/${slug}/promo-codes/${row_data.id}`)
    }

    const onAdd = async () =>{
        props.history.push(`/${slug}/promo-codes/new`)
    }

    const freeAction = (
        <Hide hide={!promo_code_perm.add}>
            <AddButton onClick={onAdd}>Add Promo Code</AddButton>
        </Hide>
    );

    return(
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="PromoCodes"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PromoCodesURL}
                    free_action={promo_code_perm.add && freeAction}
                    onDelete={promo_code_perm.delete && onDelete}
                    onEdit={promo_code_perm.edit && onEdit}
                    open_in_page={Cookies.get("promo_codes_open_in_page")}
                />
            </Col>
        </Row>
    )
}
