import DynamicAutocomplete from "./DynamicAutocomplete";
import {VendorsListAllURL} from "../../urls";

export const VendorAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;

    return(
        <DynamicAutocomplete
            {...props}
            exclude={props.exclude}
            fetch_url={VendorsListAllURL}
            title_key="name"
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all]}
            params={{fetch_all}}
        />
    )
}
