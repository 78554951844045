import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import Hide from "../../global/components/Hide";
import {CustomTabPane} from "../../global/components/CustomTabPane";
import {getUserType} from "../../global/auth_functions";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {PromoCodeDetails} from "./PromoCodeDetails";


export const PromoCodeInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const url_query = new URLSearchParams(props.location.search);

    const [promo_code_id] = React.useState(url_params.id || 'new');

    const [is_new, setIsNew] = useState(promo_code_id === "new");
    const [user_type] = useState(getUserType());

    const [promo_code_data, setPromoCodeData] = useState("en");
    const [current_tab, setCurrentTab] = useState("1");


    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };


    return (
        <>
            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">{promo_code_id === "new" ? "Add Promo Code" :  (<>Edit Promo Code > <span className="text-black-50">{promo_code_data.title}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Usage" key="2"
                                   disabled={is_new || !checkPermission(permission_data.promo_code.view)}/>
                </Tabs>

                <Hide hide={current_tab !== "1"}>
                <PromoCodeDetails
                    {...props}
                    is_new={is_new}
                    user_type={user_type}
                    promo_code_id={promo_code_id}
                    setPromoCodeData={setPromoCodeData}
                />
                </Hide>

            </div>

        </>)
}
