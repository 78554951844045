import DynamicAutocomplete from "./DynamicAutocomplete";
import {ProductListURL} from "../../urls";

export const ProductAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;

    return(
        <DynamicAutocomplete
            {...props}
            exclude={props.exclude}
            fetch_url={ProductListURL}
            title_key="name_en"
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all]}
            params={{fetch_all}}
        />
    )
}
