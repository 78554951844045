import {Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {httpRequestWithAuth, httpRequestWithoutAuth} from "../../axios_instance";
import {isValidValue} from "../../helper_functions";
import {BaseBackEndAPIsURL} from "../../urls";

const { Option, OptGroup } = Select;


const DynamicAutocomplete = (props) => {
    let response;

    const {fetch_url, title_key, fetch_in_search, group_key, keep_current_value} = props;
    const params = props.params || {};
    const params_array = props.params_array || [];
    const [first_render, setFirstRender] = useState(true);
    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);


    const fetchData = async (search = null) => {
        setFetching(true);
        let options_data = props.static_options || [];
        const temp_params = {};

        if (isValidValue(fetch_url)){
            Object.keys(params).map(param_key=>{
                if (Array.isArray(params[param_key])) {
                    temp_params[param_key] = (params[param_key]).join(',')
                }else{
                    if (isValidValue(params[param_key])){
                        temp_params[param_key] = params[param_key]
                    }
                }
            })

            if (Array.isArray(search)) {
                search = (search || []).join(',')
            }


            if (!fetch_in_search) {
                search = "";
            }

            if (isValidValue(search)) {
                temp_params['search'] = search;
            }

            if (fetch_in_search && keep_current_value){
                if (props.multiple){
                    temp_params.include_ids = (props.value || []).join(',')
                }else{
                    temp_params.include_ids = props.value || "";
                }
            }

            let response;

            if (props.no_auth) {
                response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${fetch_url}`, "GET", null, temp_params);
            } else {
                response = await httpRequestWithAuth(fetch_url, "GET", null, temp_params);
            }


            if (response.status) {
                options_data = response.data;
            }

        }



        let new_value = "";

        if (props.mode === "multiple") {
            new_value = [];
        }

        let temp_options = isValidValue(group_key)?{}:[];


        options_data.map(option => {
            if (option.id !== props.exclude) {

                let title = ""
                if (Array.isArray(title_key)){
                    title_key.map((key, index)=>{
                        title += option[key];

                        if (index < title_key.length -1){
                            title += index === 0 ?" - ": "- "
                        }
                    })
                }else{
                    title = option[title_key]
                }


                if (isValidValue(group_key)){


                    if (!isValidValue(temp_options[option[group_key]])) {
                        temp_options[option[group_key]] = []
                    }


                    temp_options[option[group_key]].push({
                        title: title,
                        value: option.id,
                        obj: option
                    })

                }else{
                    temp_options.push({
                        title: title,
                        value: option.id,
                        obj: option
                    })
                }



                if (props.mode === "multiple") {
                    if ((props.value || []).includes(option.id)) {
                        new_value.push(option.id);
                    }
                } else {
                    if (props.value === option.id) {
                        new_value = option.id
                    }
                }
            }

        })

        if (props.select_first){

            if (!isValidValue(new_value) || (Array.isArray(new_value) && new_value.length ===0)){

                if (temp_options.length > 0){
                    if (props.mode === "multiple") {
                        new_value.push(temp_options[0].id);
                    } else {
                        new_value = temp_options[0].value
                    }
                }
            }
        }

        await handleChange(new_value);
        getObj(new_value, temp_options);

        setOptions(temp_options)
        response = null;
        setFetching(false);


    }

    useEffect(() => {

        if (first_render) {
            fetchData(props.value);
            setFirstRender(false);
        } else {
            fetchData();
        }

    }, params_array)


    const handleChange = (new_value) => {
        new_value = new_value || "";
        let old_value = props.value;
        let temp_new_value = new_value;

        if (Array.isArray(new_value)){
            old_value = (props.value || []).sort().toString();
            temp_new_value = new_value.sort().toString();
            new_value = new_value || []
        }

        if (!props.view_only && !props.disabled && old_value !== temp_new_value) {

            props.onChange(props.name, new_value);
            if (!first_render){
                getObj(new_value, options);
            }
        }

    };

    const getObj = (value, options_arr) =>{
        if (props.getFullObj && !Array.isArray(value) && Array.isArray(options_arr)){

            let new_value_obj = {};
            options_arr.map(option=>{
                if (option.value === value){
                    new_value_obj = option.obj;
                }
            })

            props.getFullObj(new_value_obj);
        }
    }


    const handleSearch = (search_text) => {

        if (fetch_in_search) {
            if (search_text.length >= (props.search_length || 3)) {
                if (isValidValue(response)) {
                    response.cancel();
                }
                fetchData(search_text);
            }
        }
    };


    const filterOption = (value, option) => {
        if (fetch_in_search) return true;
        value = (value || "").toLowerCase();
        const title = (option.children || "").toLowerCase();
        return title.includes(value);
    }


    return (
        <CustomFieldWrapper {...props}>
            <Select
                {...props}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={filterOption}
                value={props.value || (props.mode === "multiple"?[]:null)}
                mode={props.mode}
                onSearch={handleSearch}
                showSearch
                style={{width: "100%"}}
                notFoundContent={fetching ? <Spin size="small"/> : null}
                status={props.error ? "error" : null}
                onChange={handleChange}
                className={`mt-0 ${!props.disabled && props.view_only?"bg-white text-black-50":""} ${props.inputClassName}`}
                disabled={props.disabled || props.view_only}
            >

                {!isValidValue(group_key) && (options || []).map((option, index) => (
                    <Option
                        key={index}
                        value={option.value}
                    >
                        {option.title}
                    </Option>
                ))}

                {isValidValue(group_key) && Object.keys(options).map((option_group_key) => (
                    <OptGroup label={option_group_key}>
                        {(options[option_group_key] || []).map((option, index)=>(
                            <Option
                                key={`${option_group_key}-${index}`}
                                value={option.value}
                            >
                                {option.title}
                            </Option>
                        ))}

                    </OptGroup>

                ))}
            </Select>
        </CustomFieldWrapper>
    );
};

export default DynamicAutocomplete;
