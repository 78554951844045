import React from "react";
import {Route, Switch} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import {permission_data} from "../global/permissions_data";
import {Advertisements} from "../pages/general/advertisements/Advertisements";
import {VendorDocuments} from "../pages/vendors/vendors_documents/VendorDocuments";
import {Vendors} from "../pages/vendors/vendors/Vendors";
import {Users} from "../pages/users/users";
import {Rules} from "../pages/rules/rules";
import {Dashboard} from "../pages/dashboard/Dashboard";
import {VendorsCategories} from "../pages/vendors/vendors_catogeries/VendorsCategories";
import {Products} from "../pages/products/products/Products";
import {ProductInfo} from "../pages/products/products/ProductInfo";
import {Orders} from "../pages/orders/Orders";
import {OrderInfo} from "../pages/orders/OrderInfo";
import {ProductsCategories} from "../pages/products/products_catogeries/ProductsCategories";
import {VendorBrands} from "../pages/vendors/vendors_brands/VendorBrands";
import {Blogs} from "../pages/blogs/Blogs";
import {BlogInfo} from "../pages/blogs/BlogInfo";
import {PromoCodes} from "../pages/promo_codes/PromoCodes";
import {PromoCodeInfo} from "../pages/promo_codes/PromoCodeInfo";


const NotFound = (props) =>{
    // useEffect(()=>{
    //     logout();
    //     props.history.push(`${props.slug}/signin`);
    // },[])

    return(
        <h2>Not found</h2>
    )
}


export const Routes = props =>{
    return(
        <Switch>

            <ProtectedRoute {...props} title="Order Info"  path="/:slug/orders/:id" component={OrderInfo} permissions={[permission_data.order.view]}/>
            <ProtectedRoute {...props} title="Orders"  path="/:slug/orders" component={Orders} permissions={[permission_data.order.view]}/>

            <ProtectedRoute {...props} title="Add Blog"  path="/:slug/blogs/new" key="new-blog" component={BlogInfo} permissions={[permission_data.blog.add]}/>
            <ProtectedRoute {...props} title="Edit Blog"  path="/:slug/blogs/:id" key="edit-blog" component={BlogInfo} permissions={[permission_data.blog.view]}/>
            <ProtectedRoute {...props} title="Blog Articles"  path="/:slug/blogs" component={Blogs} permissions={[permission_data.blog.view]}/>

            <ProtectedRoute {...props} title="Advertisements"  path="/:slug/advertisements" component={Advertisements} permissions={[permission_data.advertisement.view]}/>



            <ProtectedRoute {...props} title="Products Categories" path="/:slug/products-categories" component={ProductsCategories} permissions={[permission_data.product_category.view]}/>

            <ProtectedRoute {...props} title="Products" path="/:slug/products/:id" key="product-edit" component={ProductInfo} permissions={[permission_data.product.view]}/>
            <ProtectedRoute {...props} title="Products" path="/:slug/products/new" key="product-new" component={ProductInfo} permissions={[permission_data.product.add]}/>
            <ProtectedRoute {...props} title="Products" path="/:slug/products" component={Products} permissions={[permission_data.product.view]}/>


            <ProtectedRoute {...props} title="Promo Codes" path="/:slug/promo-codes/:id" key="promo-codes-edit" component={PromoCodeInfo} permissions={[permission_data.promo_code.view]}/>
            <ProtectedRoute {...props} title="Promo Codes" path="/:slug/promo-codes/new" key="promo-codes-new" component={PromoCodeInfo} permissions={[permission_data.promo_code.add]}/>
            <ProtectedRoute {...props} title="Promo Codes" path="/:slug/promo-codes" component={PromoCodes} permissions={[permission_data.promo_code.view]}/>

            <ProtectedRoute {...props} title="Vendors Categories" path="/:slug/vendors-categories" component={VendorsCategories} permissions={[permission_data.vendor_category.view]}/>

            <ProtectedRoute {...props} title="Vendors Brands" path="/:slug/vendors-brands" component={VendorBrands} permissions={[permission_data.vendor_brand.view]}/>

            <ProtectedRoute {...props} title="Vendors Documents" path="/:slug/vendors-documents" component={VendorDocuments} permissions={[permission_data.vendor_document.view]}/>

            <ProtectedRoute {...props} title="Vendors" path="/:slug/vendors" component={Vendors} permissions={[permission_data.vendor.view]}/>

            <ProtectedRoute {...props} title="Users"  path="/:slug/users" component={Users} permissions={[permission_data.user.view]}/>

            <ProtectedRoute {...props} title="Rules"  path="/:slug/rules" component={Rules} permissions={[permission_data.rule.view]}/>

            <ProtectedRoute {...props} title="Dashboard"  path="/:slug/dashboard" component={Dashboard} permissions={true}/>

            <Route path="*" component={NotFound}/>

        </Switch>
    )
}
