import {useEffect, useState} from "react";
import {Button, Col, Input, Pagination, Popconfirm, Table} from "antd";
import CustomSelectField from "./fields/CustomSelectField";
import {CustomSearchField} from "./fields/CustomSearchField";
import Hide from "./Hide";
import {httpRequestWithAuth} from "../axios_instance";
import {isValidValue, showError} from "../helper_functions";
import useWindowSize from "../useWindowSize";
import {DeleteFilled, EditFilled} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";

// {
//     data: rows,
//         current_page: page,
//     hasNextPage: item_per_page * page < count,
//     item_per_page: item_per_page,
//     hasPreviousPage: page > 1,
//     nextPage: page + 1,
//     previousPage: Math.min(1, page - 1),
//     lastPage: Math.max(1, Math.ceil(count / item_per_page)),
//     total: count
// }


const page_size_options = [
    {title: "5", value: 5},
    {title: "10", value: 10},
    {title: "20", value: 20},
    {title: "25", value: 25},
    {title: "50", value: 50},
    {title: "100", value: 100},
    {title: "250", value: 250},
    {title: "500", value: 500},
    {title: "1000", value: 1000},
    {title: "1500", value: 1500},
    {title: "2000", value: 2000},
    {title: "2500", value: 2500},
];

export const CustomDynamicTable = (props) => {
    const allow_export = props.allow_export || false;
    const export_columns = props.export_columns || "";
    const [export_data, setExportData] = useState([]);
    const [open_in_page, setOpenInPage] = useState(props.open_in_page)
    const custom_params = props.custom_params || {};
    const {inner_width} = useWindowSize();
    const [fetch_data, setFetchData] = useState(0);
    const [reload_columns, setReloadColumns] = useState(false);
    const [data, setData] = useState({
        data: [],
        current_page: 1,
        has_next_page: false,
        item_per_page: 25,
        has_previous_page: false,
        next_page: 1,
        previous_page: 1,
        last_page: 1,
        total: 0
    });

    const [first_render, setFirstRender] = useState(true);
    const [loading, setLoading] = useState(true);
    const [delete_loading, setDeleteLoading] = useState({});
    const [table_columns, setTableColumns] = useState(props.columns || []);
    const [table_options, setTableOptions] = useState({
        current_page: 1,
        item_per_page: 25,
        search: props.default_search
    });


    const changeTableOption = async (name, value, reload = true) => {
        let temp_options = {...table_options};

        if (name === "current_page") {
            value = Math.min(value, data.last_page);
            value = Math.max(value, 1);
        }
        //
        temp_options[name] = value;

        //
        if (name === "search" && !isValidValue(value)) {
            temp_options['current_page'] = 1;
        }

        await setTableOptions(temp_options);

        if (name !== "search" && reload) {
            reloadData();
        }

        if (name === "search" && !isValidValue(value)) {
            reloadData();
        }

    };

    const reloadData = async (rest_page = false) => {
        if (rest_page) {
            await setTableOptions({...table_options, current_page: 1});
        }

        await setFetchData(Math.random());
    }

    const handleDelete = async (row_data) => {
        await setDeleteLoading({...delete_loading, [row_data.id]: true});
        await setReloadColumns(true)
        await props.onDelete(row_data);
        await setDeleteLoading({...delete_loading, [row_data.id]: false});
        await reloadData(true);
        await setReloadColumns(false);
    }


    const updateExportData = (current_data=[]) =>{
        let temp_data = [];

        if (Array.isArray(export_columns)){

            for (let i = 0; i < current_data.length; i++) {
                const item = current_data[i]
                let new_item = {};

                for (let j = 0; j < export_columns.length; j++) {
                    const value = item[export_columns[j].key];
                    new_item[export_columns[j].title] = export_columns[j].format? export_columns[j].format(value): value;
                }

                temp_data.push(new_item)
            }

        }else{
            temp_data = current_data;
        }

        setExportData(temp_data);
    }


    useEffect(() => {
        let temp_column = [];

        (props.columns || []).map(Col => {
            if (!Col.hide) {
                temp_column.push(Col);
            }
        })

        if (inner_width <= 991 && props.mobile_columns) {
            temp_column = props.mobile_columns;
        } else {

            if (props.onDelete || props.onEdit) {
                temp_column.push({
                    title: "Actions",
                    dataKey: "id",
                    align: "center",
                    render: (value, row_data) => (
                        <div className="d-flex justify-content-center">
                            <Hide hide={!props.onEdit}>
                                <Button
                                    onClick={() => props.onEdit(row_data, data.current_page)}
                                    disabled={isValidValue(props.disableEdit) ? props.disableEdit(row_data) : false}
                                    className="d-flex align-items-center m-1" type="primary"
                                    icon={!props.remove_action_icon && <EditFilled className="m-0 p-0"/>}>
                                    {props.edit_label || "Edit"}
                                </Button>
                            </Hide>

                            <Hide hide={!props.onDelete}>
                                <Popconfirm
                                    disabled={isValidValue(props.disableDelete) ? props.disableDelete(row_data) : false}
                                    placement="bottomRight"
                                    title={isValidValue(props.deleteLabelConfirm) ? props.deleteLabelConfirm(row_data) : "Are you sure to delete this row ??"}
                                    onConfirm={() => handleDelete(row_data)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        loading={delete_loading[row_data.id]}
                                        disabled={isValidValue(props.disableDelete) ? props.disableDelete(row_data) : false}
                                        className="d-flex align-items-center m-1" type="danger"
                                        icon={!props.remove_action_icon && <DeleteFilled className="m-0 p-0"/>}>
                                        {props.delete_label || "Delete"}
                                    </Button>
                                </Popconfirm>

                            </Hide>
                        </div>

                    )
                })
            }
        }


        setTableColumns(temp_column)


    }, [inner_width, reload_columns, props.columns, props.mobile_columns, data.current_page])


    useEffect(() => {
        const fetchData = async () => {
            await setLoading(true);

            const params = {};

            Object.keys(table_options).map(Key => {
                params[Key] = table_options[Key];
            });

            Object.keys(custom_params).map(Key => {
                if (isValidValue(custom_params[Key])) {
                    params[Key] = custom_params[Key];
                }
            });

            if (isValidValue(open_in_page)){
                params.current_page = open_in_page;
                setOpenInPage("")
            }


            let response = await httpRequestWithAuth(props.fetch_url, "GET", null, params);
            // let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${props.fetch_url}`, "GET", null, params);

            if (response.status) {
                setData(response.data);
                updateExportData(response.data.data)
            } else {
                showError("Fetch data failed", response.error_feedback.non_field_errors);
            }

            await setLoading(false);
        }

        fetchData();
    }, [fetch_data])


    useEffect(() => {
        if (!first_render) {
            setFetchData(Math.random());
        } else {
            setFirstRender(false);
        }
    }, [props.reload_data])


    const formSubmit = e => {
        e.preventDefault();
        reloadData(false);
    }
    return (
        <div className="w-100">

            <Hide hide={!(props.title || props.free_action)}>
                <Col xs={24} className="d-md-flex justify-content-between text-center mb-2">

                    <h2 className="font-size-22">
                        {props.title}
                    </h2>


                    <div className="d-flex justify-content-center">
                        <div className="d-md-flex w-100">

                            <Hide hide={!allow_export}>
                                <CSVLink className="pe-2" c data={export_data} filename={`${props.title} - ${moment().format("DD-MM-YYYY__hh:mm:ss")}`}>
                                    <Button
                                        block
                                        type="primary"
                                        className="m-1"
                                    >
                                        Export Data
                                    </Button>
                                </CSVLink>
                            </Hide>


                            {props.free_action}
                        </div>

                    </div>

                </Col>
            </Hide>

            <Col xs={24} sm={24} md={10} className="mb-3">
                <CustomSearchField
                    onSearch={() => reloadData(true)}
                    onChange={changeTableOption}
                    name="search"
                />
            </Col>


            <Hide hide={!isValidValue(props.filter_toolbar)}>
                <Col xs={24} className="mb-3 ">
                    {props.filter_toolbar}
                </Col>
            </Hide>

            <Table
                {...props?.table_props}
                loading={loading}
                columns={table_columns}
                dataSource={data.data || []}
                pagination={false}
                style={{minHeight: props.table_min_height || 425}}
                scroll={{
                    x: 750,
                }}
                expandable={props.expandable}
            />


            <div className="w-100 d-sm-flex justify-content-between mt-3">
                <div className="ps-2 d-flex align-items-center justify-sm-content-start justify-content-center">
                    <CustomSelectField
                        inline
                        label="Item Per Page"
                        name="item_per_page"
                        value={table_options.item_per_page}
                        onChange={changeTableOption}
                        options={page_size_options}
                        style={{minWidth: 60}}
                    />
                    <label className="ms-2 font-size-14">Total: {data.total}</label>
                </div>


                <form className="d-flex justify-sm-content-end justify-content-center align-items-center mt-3 mt-sm-0"
                      onSubmit={formSubmit}>
                    {/*<Button*/}
                    {/*    disabled={!data.has_previous_page}*/}
                    {/*    type="link"*/}
                    {/*    onClick={() => changeTableOption('current_page', data.previous_page)}*/}
                    {/*    className="font-size-14"*/}
                    {/*>*/}
                    {/*    Previous*/}
                    {/*</Button>*/}

                    {/*<Input*/}
                    {/*    size="small"*/}
                    {/*    className="ms-2 w-25 text-center"*/}
                    {/*    value={table_options.current_page}*/}
                    {/*    onChange={e => changeTableOption('current_page', e.target.value, false)}*/}
                    {/*    onBlur={() => reloadData(false)}*/}
                    {/*/>*/}

                    {/*<label className="ms-2">*/}
                    {/*    /*/}
                    {/*</label>*/}

                    {/*<label className="ms-2">*/}
                    {/*    {data.last_page || 1}*/}
                    {/*</label>*/}

                    {/*<Button*/}
                    {/*    disabled={!data.has_next_page} type="link"*/}
                    {/*    className="ms-2 font-size-14"*/}
                    {/*    onClick={() => changeTableOption('current_page', data.next_page)}*/}
                    {/*>*/}
                    {/*    Next*/}
                    {/*</Button>*/}
                    <Pagination
                        current={data.current_page}
                        total={data.total}
                        pageSize={data.item_per_page}
                        showSizeChanger={false}
                        onChange={(number) => changeTableOption('current_page', number)}
                    />
                </form>

            </div>

        </div>
    )
}
